import $ from 'jquery'

class Offcanvas {
    constructor() {
        this.offcanvasEl = $('.c-offcanvas')
        this.menuEl = this.offcanvasEl.find('.c-nav-primary')
        this.subMenus = this.menuEl.find('.sub-menu')
        this.topLevelMenuItem = this.menuEl.find('> .menu > .menu__item > a')
        this.menuItemsWithChildren = this.menuEl.find('.menu__item--has-children > a')
        this.sidebarImage = this.offcanvasEl.find('.c-offcanvas__background-image')
        this.defaultSidebarImage = this.offcanvasEl.find('.c-offcanvas__background-image--default')
        this.headerNavicon = $('.c-navicon')
        this.offcanvasNavicon = $('.c-offcanvas .c-navicon')
        this.documentBody = $('body')

        this.createBackButtons()

        this.bindEventListeners()
    }

    bindEventListeners() {
        // Update the sidebar image when a top level menu item is hovered
        this.topLevelMenuItem.on('mouseover', (e) => this.showRelatedSidebarImage(e))

        // Handle clicking a menu item with children
        this.menuItemsWithChildren.on('click', (e) => this.revealSubMenu(e))

        // Handle clicking a sub menu back button
        this.menuEl.on('click', '.menu__button', (e) => this.hideSubMenu(e))

        // Reset the offcanvas state when the offcanvas is closed
        this.offcanvasNavicon.on('click', () => this.resetState())

        this.headerNavicon.on('click', () => this.revealOffcanvas())
    }

    revealOffcanvas() {
        this.documentBody.trigger('revealOffcanvas')
        // console.log("offcanvas ", this.offcanvasEl, "menu with items ", this.menuItemsWithChildren, "offcanvas navicon ", this.offcanvasNavicon)
    }

    revealSubMenu(event) {
        event.preventDefault()
        $('.menu__item--has-children').removeClass('is-active')
        $(event.target).parent().addClass('is-active')
        this.offcanvasEl.addClass('sub-menu-is-visible')
    }

    showRelatedSidebarImage(event) {
        let menuItemId = $(event.target).parent().attr('id')
        let relatedSidebarImageEl = $('.c-offcanvas__background-image.' + menuItemId)

        this.sidebarImage.removeClass('is-active')

        if (relatedSidebarImageEl.length) {
            relatedSidebarImageEl.addClass('is-active')
        } else {
            this.defaultSidebarImage.addClass('is-active')
        }
    }

    hideSubMenu(event) {
        event.preventDefault()
        $(event.target).parents('.menu__item').removeClass('is-active')
        this.offcanvasEl.removeClass('sub-menu-is-visible')
    }

    createBackButtons() {
        this.subMenus.each(function () {
            $(this).prepend('<button class="menu__button"/>')
        })
    }

    resetState() {
        setTimeout(() => {
            this.offcanvasEl.removeClass('sub-menu-is-visible')
            this.menuItemsWithChildren.parent().removeClass('is-active')
        }, 1000)
    }
}

export default Offcanvas