import { filter, join, map } from 'lodash'

class LdeBookingForm {
    constructor(el) {
        this.elements = {
            form: el,
            holidayType: el.find('.js-lde-booking-form-holiday-type'),
            pitchSizeFieldGroup: el.find('.js-lde-booking-form-pitch-size'),
            pitchSizeDropdown: el.find('.js-lde-booking-form-pitch-size select'),
            dogFriendlyFieldGroup: el.find('.js-lde-booking-form-dog-friendly'),
            dogFriendlyCheckbox: el.find('.js-lde-booking-form-dog-friendly input'),
            stayFieldGroup: el.find('.js-lde-booking-form-stay-fields'),
            hiddenRoomFilter: el.find('.js-lde-booking-form-room-filter'),
            submitButton: el.find('.js-lde-booking-form-button'),
            occupantsSelect: el.find('.js-lde-booking-form-occupants'),
            formFeedback: el.find('.js-lde-booking-form-feedback'),
        }

        this.options = el.data('module-options')

        this.state = {
            holidayType: {},
            validRooms: [],
            limitedNights: false
        }

        this.elements.holidayType.on('change', this.setHolidayType.bind(this))
        this.elements.pitchSizeDropdown.on('change', this.setHolidayType.bind(this))
        this.elements.dogFriendlyCheckbox.on('change', this.setDogFriendly.bind(this))

        $(document).ready(() => {
            this.setHolidayType()
        })
    }

    setHolidayType() {
        let holidayType = this.elements.holidayType.val()

        if (holidayType) {
            this.updateState(holidayType)
            this.setRoomFilterValue()
            this.setMaxOccupants()
            this.enableStayForm()
        } else {
            this.resetState()
            this.disableStayForm()
        }

        this.togglePitchSizeField()
        this.toggleDogFriendlyField()
    }

    setDogFriendly() {
        let holidayType = this.elements.holidayType.val()
        this.updateState(holidayType)
        this.setRoomFilterValue()
    }

    updateState(holidayType) {
        this.state.holidayType = this.options.holidayTypes[holidayType]
        this.state.validRooms = this.getValidRooms()
    }

    resetState() {
        this.state = {
            holidayType: {
                hasPitchSizeFilter: false,
                hasDogFriendlyFilter: false
            }
        }
    }

    enableStayForm() {
        this.elements.stayFieldGroup.show()
        this.elements.submitButton.prop('disabled', false)
    }

    disableStayForm() {
        this.elements.stayFieldGroup.hide()
        this.elements.submitButton.prop('disabled', true)
    }

    getValidRooms() {
        if (this.isDogFriendlySearch()) {
            return this.getDogFriendlyRooms()
        }

        if (this.isPitchSizeSearch()) {
            return this.getValidRoomsForPitchSize()
        }

        return map(this.state.holidayType.rooms, 'id')
    }

    isDogFriendlySearch() {
        if (!this.state.holidayType.hasDogFriendlyFilter) {
            return false
        }

        if (!this.elements.dogFriendlyCheckbox.prop('checked')) {
            return false
        }

        return true
    }

    isPitchSizeSearch() {
        return this.state.holidayType.hasPitchSizeFilter
    }

    getDogFriendlyRooms() {
        return map(filter(this.state.holidayType.rooms, 'dogFriendly'), 'id')
    }

    getValidRoomsForPitchSize() {
        let selectedPitchSize = parseInt(this.elements.pitchSizeDropdown.val())

        if (isNaN(selectedPitchSize)) {
            return map(this.state.holidayType.rooms, 'id')
        }

        return map(filter(this.state.holidayType.rooms, {
            pitchSize: selectedPitchSize
        }), 'id')
    }

    togglePitchSizeField() {
        if (this.state.holidayType.hasPitchSizeFilter) {
            this.elements.pitchSizeFieldGroup.show()
        } else {
            this.elements.pitchSizeFieldGroup.hide()
        }
    }

    toggleDogFriendlyField() {
        if (this.state.holidayType.hasDogFriendlyFilter) {
            this.elements.dogFriendlyFieldGroup.show()
        } else {
            this.elements.dogFriendlyFieldGroup.hide()
        }
    }

    setRoomFilterValue() {
        this.elements.hiddenRoomFilter.val(join(this.state.validRooms, ';'))
    }

    setMaxOccupants() {
        let maxOccupants = this.state.holidayType.maxOccupants

        this.elements.occupantsSelect.each(function() {
            let occupants = $(this).attr('name')
            let maxOccupantsOfType = maxOccupants[occupants]

            $(this).find('option')
                .filter((i, opt) => $(opt).val() > maxOccupantsOfType)
                .attr('disabled', true)
        });
    }
}

export default LdeBookingForm
