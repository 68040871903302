import Offcanvas from './modules/Offcanvas'
import RemoveClassMethods from './modules/RemoveClassMethods'
import AddClassMethods from './modules/AddClassMethods'
import InstagramLink from './modules/InstagramLink'
import ImageOrientation from './modules/ImageOrientation'
import InsertSpanAfter from './modules/InsertSpanAfter'
import AddHtmlAsContent from './modules/AddHtmlAsContent'
import MediaCaptions from './modules/MediaCaptions'
import Clamp from './modules/Clamp'
import SiblingHover from './modules/SiblingHover'
import ToggleParentMethods from './modules/ToggleParentMethods'
import InsertElementInto from './modules/InsertElementInto'
import MediaImageChange from './modules/MediaImageChange'

import LdeBookingForm from '../../widgets/lde-booking-form/js/LdeBookingForm'

$('.js-lde-booking-form').each(function() {
    new LdeBookingForm($(this))
})

new Offcanvas()
new ImageOrientation()
new MediaCaptions()
new MediaImageChange()

const appendHeadingToMedia = new InsertElementInto (
    '.c-heading--media-grid-filter .c-heading__heading',
    '.c-grid-filter__filter__label',
)

const appendLoaderToRemodal = new InsertElementInto (
    '.c-remodal-loading',
    '.c-remodal-wrapper'
)


const toggleBookingModalActiveActive = new ToggleParentMethods (
    '.c-button--read-more',
    '.c-media',
    'is-active',
)

const navFooterHover = new SiblingHover (
    '.c-nav-footer .menu__item'
)

const mediaText = new Clamp (
    '.c-media.no-layout.no-variation .c-media__text', 2
)

const ctaGridFilterText = new Clamp (
    '.c-grid-filter .c-cta__text', 4
)

if ($(window).width() > 1024) {
    const mediaGridFilterTextAdvancedSearch = new Clamp (
        '.c-grid-filter--media-advanced-search .c-media__text', 2
    )
}

const ctaGridFilterHeading = new Clamp (
    '.c-grid-filter .c-cta__heading', 2
)

const removeSubmenuActiveClass = new RemoveClassMethods (
    '.c-offcanvas--close-sub-menu, .s-page-wrap',
    '.c-offcanvas',
    'sub-menu-is-visible'
)

const removePrimaryNavActiveClass = new RemoveClassMethods (
    '.c-offcanvas--close-sub-menu, .s-page-wrap',
    '.menu__item--has-children',
    'is-active'
)

const removeNavActiveFromBody = new RemoveClassMethods (
    '.s-page-wrap',
    'body',
    'navigation--is-active'
)

const removeNavActiveFromHtml = new RemoveClassMethods (
    '.s-page-wrap',
    'html',
    'navigation--is-active'
)

const removeActiveModalLoading = new RemoveClassMethods (
    '.remodal-close, .remodal-wrapper',
    'html',
    'remodal--is-loading'
)

const addActiveModalLoading = new AddClassMethods (
    '.c-button[data-module="ajax-modal"]',
    'html',
    'remodal--is-loading'
)

const instagramLinkWrap = new InstagramLink (
    '.c-instagram-posts__subheading',
    '.c-page-footer--hidden-instagram-link'
)

const addHtmlAsClass = new AddHtmlAsContent (
    '.c-nav-primary .menu__item a'
)

const addHtmlAsClassButtonBook = new AddHtmlAsContent (
    '.c-button--book'
)

const addHtmlAsClassButtonHollow = new AddHtmlAsContent (
    '.c-button--hollow'
)

const addHtmlAsClassButtonHollowGray = new AddHtmlAsContent (
    '.c-button--hollow-gray'
)

const addHtmlAsClassButtonConversion = new AddHtmlAsContent (
    '.c-button--conversion'
)

const addHtmlAsClassButtonPostGridFilter = new AddHtmlAsContent (
    '.c-grid-filter--posts .c-cta__button'
)

const addHtmlAsClassSubmitButton = new AddHtmlAsContent (
    '.gform_button'
)


const insertSpanIntoMenuItem = new InsertSpanAfter (
    '<span></span>',
    '.c-nav-primary .menu__item--has-children a'
)

document.addEventListener("DOMContentLoaded", function(event) {

    document.getElementById('enableRecite').addEventListener("click", function() {
    
        loadService();
    
    });
});